import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import FlexibleContent from "components/FlexibleContent/FlexibleContent"

const NotFound404 = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allWp {
        edges {
          node {
            ...optionsFragment
          }
        }
      }
    }`
  )

  const { page404 } = data.allWp?.edges[0]?.node?.options?.acfOptions

  const acfBlocks = page404?.template?.acfFlexibleContent?.blocks
  const title = page404?.seo?.title 

  return (
    <Layout title={title} seo={page404?.seo}>
      <FlexibleContent blocks={acfBlocks} />
    </Layout>
  )
}

export default NotFound404